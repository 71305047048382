<script>
import Tooltip2Vue from "@/components/Tooltip2Vue/Tooltip2Vue";
import LegoSelectCollaborator from "@/components/Lego/LegoSelectCollaborator.vue";
// import LegoBtn from "@/components/Lego/LegoBtn.vue";
import VSAvatarGroup from "@/components/Tooltip2Vue/VSAvatarGroup.vue";

export default {
  components: {
    Tooltip2Vue,
    LegoSelectCollaborator,
    // LegoBtn,
    VSAvatarGroup,
  },
  props: {
    collaborators: {
      type: Array,
      required: false,
      default: () => [],
    },
    card: {
      type: Object,
      required: true,
    },
    lite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      deleting: [],
    };
  },
  computed: {
    roleLevel() {
      return this.$store.state.lego.currencyRole.level;
    },
    assignments() {
      return this.card.assignments;
    },
    selectedCollaborators() {
      if (this.assignments != undefined) {
        const data = this.assignments.map((assignment) =>
          this.collaborators.find((c) => c.id == assignment.collaboratorId)
        );
        console.log("data", data);

        return data;
      } else {
        return [];
      }
    },
    isDependent() {
      return this.card.legoColumnId == null;
    },
  },
  methods: {
    addAssignment(collaborator) {
      const assignment = {
        collaboratorId: collaborator.id,
        personaId: collaborator.personaId,
        LegoCardId: this.card.id,
      };
      this.$store.dispatch("lego/addAssignment", assignment);
    },
    async deleteAssignment(assignment) {
      this.deleting.push(assignment.id);
      await this.$store.dispatch("lego/deleteAssignment", assignment);
      this.deleting = this.deleting.filter((id) => id != assignment.id);
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center px-1">
    <Tooltip2Vue position="bottom">
      <slot>
        <i
          v-b-tooltip.hover
          title="Agregar responsables"
          class="fas fa-chevron-down mr-2 text-white-50"
        ></i>
        <span v-if="!selectedCollaborators.length">
          {{ selectedCollaborators.length + " Resp." }}
        </span>
        <!-- <LegoBtn>
          <span v-if="!lite"><i class="fa-regular fa-user"></i></span>
          <span v-if="lite">
            {{ selectedCollaborators.length }}
            <i class="fas fa-chevron-down"></i>
          </span>
          <span v-else>
            {{
              selectedCollaborators.length
                ? selectedCollaborators.length + " Resp."
                : "Responsables"
            }}
          </span>
        </LegoBtn> -->
      </slot>
      <template #tooltip>
        <div class="tp-container">
          <div class="mb-2">
            <p>
              <span>Responsables</span>
            </p>
            <div v-if="roleLevel < 3 && isDependent">
              <p>
                No es posible asignar responsables a tareas que dependen de
                otras.
              </p>
              <p>Por favor, asigna responsables en la tarea.</p>
            </div>
          </div>
          <LegoSelectCollaborator
            v-if="roleLevel < 3 && !isDependent"
            :selectedCollaborators="selectedCollaborators"
            :collaborators="collaborators"
            @input="addAssignment"
          />
          <div class="responsables">
            <div
              class="responsable"
              v-for="assignment in assignments"
              :key="assignment.id"
            >
              <div class="persona">
                <div>
                  <i class="fa-regular fa-user"></i>
                </div>
                <div>
                  <span>
                    {{ assignment.collaborator.name }}
                  </span>
                </div>
              </div>
              <div
                @click="deleteAssignment(assignment)"
                v-if="roleLevel < 3 && !isDependent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon-minus"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Tooltip2Vue>
    <VSAvatarGroup :listAvatars="selectedCollaborators" :max="lite ? 3 : 5" />
  </div>
</template>

<style scoped>
.btn-shadow {
  padding: 0.5rem;
  border: solid 1px #cacaca;
  /* background-color: transparent; */
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.tp-container {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 8px;
  cursor: auto;
  width: 300px;
  text-align: start;
  border: solid 1px rgba(0, 0, 0, 0.25);
  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
  }
}

.responsable:first-of-type {
  margin-top: 1rem;
}

.responsable {
  display: flex;
  padding: 0.75rem 0;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.icon-minus {
  transition: 0.3s;
  height: 24px;
  width: 24px;
  border-radius: 5px;
  cursor: pointer;
  stroke: silver;
}

.icon-minus:hover {
  /* background-color: rgba(255,255,255,.2); */
  stroke: var(--lego-danger);
}

.persona {
  display: flex;
  gap: 0.5rem;
}
</style>
