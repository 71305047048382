<script>
import moment from "moment-timezone";
import LegoControlCardDone from "@/components/Lego/LegoControlCardDone.vue";
import LegoControlCardDate from "@/components/Lego/LegoControlCardDate.vue";
import LegoControlCardAssignments from "@/components/Lego/LegoControlCardAssignments.vue";
import progressBubble from "@/components/progress/progressBubble.vue";

export default {
  components: {
    LegoControlCardDone,
    LegoControlCardDate,
    LegoControlCardAssignments,
    progressBubble,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    ganttTime: {
      type: Array,
      required: true,
    },
    isDependent: {
      type: Boolean,
      default: false,
    },
    columnsToShow: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    color() {
      return this.task.color;
    },
    collaborators() {
      return this.$store.state.lego.board.collaborators;
    },
    columns() {
      return this.columnsToShow.map((c) => c.name);
    },
    todayDate() {
      return new Date().getDate();
    },
    todayMonth() {
      return new Date().getMonth();
    },
  },
  methods: {
    calcularProgreso(checklists) {
      let totalTareas = 0;
      let tareasCompletadas = 0;

      checklists?.forEach((checklist) => {
        checklist.items.forEach((item) => {
          totalTareas++;
          if (item.isDone == true) {
            tareasCompletadas++;
          }
        });
      });

      const porcentaje = (tareasCompletadas / totalTareas) * 100;
      return totalTareas > 0 ? porcentaje : 0;
    },
    timesOfDays(task) {
      const startDate = new Date(task.startDate);
      const dueDate = new Date(task.dueDate);

      if (task.startDate && task.dueDate) {
        const days = Math.round((dueDate - startDate) / (1000 * 60 * 60 * 24));
        return days;
      } else if (task.startDate && !task.dueDate) {
        const today = new Date();
        const days = Math.round((today - startDate) / (1000 * 60 * 60 * 24));
        return days;
      } else if (task.dueDate && !task.startDate) {
        const today = new Date();
        const days = Math.round((dueDate - today) / (1000 * 60 * 60 * 24));
        return days;
      } else {
        return "";
      }
    },
    dayFormat(date, days = 0) {
      date.setDate(date.getDate() + days);
      return {
        date: moment(date).tz("America/Bogota").format("DD-MMM-YY"),
        day: moment(date).tz("America/Bogota").format("ddd").slice(0, 2),
        dateNumber: date,
      };
    },
    evalDate({ startDate, dueDate }, currencyDate) {
      if (startDate && dueDate) {
        if (
          currencyDate >= new Date(startDate) &&
          currencyDate <= new Date(dueDate)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return "";
      }
    },
    setBackground(task, date) {
      if (this.evalDate(task, date.dateNumber)) {
        return {
          backgroundColor: task.color,
        };
      } else {
        return {};
      }
    },
    isToday(date) {
      return (
        date.day == this.todayDate &&
        date.dateNumber.getMonth() == this.todayMonth
      );
    },
  },
};
</script>

<template>
  <div class="gantt-row" :class="{ isDependent }">
    <div v-if="columns.includes('color')" class="color drag-handle"></div>
    <router-link
      v-if="columns.includes('title')"
      class="title-task"
      :to="{ name: 'LegoTarjeta', params: { cardId: task.id } }"
    >
      <span>
        {{ task.title }}
      </span>
      <!-- progreso -->
      <progress-bubble
        v-b-tooltip.hover
        title="Progreso"
        size="xs"
        :percent="calcularProgreso(task.checklists)"
      />
    </router-link>

    <LegoControlCardDone
      v-if="columns.includes('isDone')"
      class="controls-gantt"
      :card="task"
      lite
    />

    <LegoControlCardAssignments
      v-if="columns.includes('assignments')"
      :collaborators="collaborators"
      :card="task"
      class="controls-gantt w-100"
      lite
    />

    <LegoControlCardDate
      v-if="columns.includes('dates')"
      class="controls-gantt"
      disabled
      :card="task"
      position="right-start"
    />

    <div
      v-if="columns.includes('days')"
      class="cursor-context-menu text-white-50"
    >
      {{ timesOfDays(task) }}
    </div>

    <div v-if="columns.includes('gantt')" class="gantt-col-date">
      <div
        v-for="date in ganttTime"
        :key="`${date.dateNumber}`"
        :class="{ isToday: isToday(date) }"
        :style="setBackground(task, date)"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.gantt-row > *,
.gantt-col-date > * {
  border-inline: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  /* .dark & {
    border-color: rgba(255, 255, 255, 0.05);
  } */
}
.dark .gantt-row > *,
.dark .gantt-col-date > * {
  /* border-inline: 1px solid #cacaca; */
  border-bottom: 1px solid #ffffff10;
  border-inline: 1px solid #ffffff0e;

  /* .dark & {
    border-color: rgba(255, 255, 255, 0.05);
  } */
}
.gantt-row:hover {
  background-color: var(--lego-bg);
}

.cursor-context-menu {
  cursor: context-menu;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-span-3 {
  grid-column-start: 2;
  grid-column-end: span 3;
  text-align: start;
  display: flex;
  align-items: center;
}

.title-task {
  padding: 0.5rem;
  padding-left: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.isDependent .title-task {
  padding-left: 2rem;
  & span {
    color: gray;
    .dark & {
      color: #ffffff80;
    }
  }
}

.color {
  background-color: v-bind(color);
}

.controls-gantt :deep(.btn-shadow) {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.controls-gantt {
  border-radius: 0px;
}

.isToday {
  background-color: #d1d1d1;
  .dark & {
    background-color: rgba(255, 255, 255, 0.1);
  }
  border: none;
}
.drag-handle {
  cursor: grab;
}
</style>
